/*
 * @version
 * @package
 * @subpackage
 * @author		James Hansen
 * @link		http://www.publivate.com
 * @license		License GNU General Public License version 2 or later
 */

var Publivate = {
	baseUrl: '',
	$document: null,
	dependenciesLoadedEvent: null,

	getWb: function () {
		return Publivate.$document
	},
	one: function (func) {
		if (Publivate.getWb()) {
			Publivate.getWb().one(Publivate.dependenciesLoadedEvent, func);
		} else {
			func();
		}
	},

    /**
	 *
     * @param parent jQuery object
     * @param position Default 'center', if position is 'center' assumes parent is relative position
     */
	displayWait : function (parent, position, size){
		var sz = size || '';
		var pos = position || 'center';
		$wait = jQuery('<i class="fa fa-spinner fa-spin"></i>');

		$wait.addClass('fa-'+sz);

		switch(pos) {
			case 'center' :
				$wait.css('position' , 'absolute');
				Publivate.centerElement(parent, $wait);
				break;
		}
	},

	removeWait : function(parent){
		var $parent = jQuery(parent);
		$parent.find('.fa-spinner').remove();
	},

    /**
	 * Centers an element to its parent which would have its position set relative.
     * @param parent jQuery object
     * @param el jQuery object
     */
	centerElement: function(parent, el){
		parent.append(el);
		el.css({
            top: (parent.outerHeight(true) / 2 - el.outerHeight(true) / 2) + 'px',
            left: (parent.outerWidth(true) / 2 - el.outerWidth(true) / 2) + 'px',
        });
	}
};

/**
 *
 * @param {type} $
 * @param {type.} response
 * @returns {Boolean}@param $ jQuery
 * @param response ajax response from server.
 */
Publivate.ajaxLoginPageCheck = function ($, response) {

	if (typeof response !== "string") {
		return false;
	}

	if ($(jQuery.trim(response)).find('#cb_cb_comp_login').length) {
		window.location.href = Publivate.PV_LOGIN_URL;
		return true;
	}

	return false;
};

/**'
 * Check all the table's rows checkboxes.
 * @param string id
 */
Publivate.checkAll = function (search) {
	jQuery(search).prop('checked', !jQuery(search).prop('checked'));
};

/**
 * Alerts users to message in browser.
 * @param string msg
 */
Publivate.log = function (msg, type, wait) {
	wait = wait || 3000;
	alertify.log(msg, type, wait);
};

Publivate.alert = function (msg) {
	alertify.set({
		labels: {
			ok: Joomla.JText._('Ok', 'Ok')
		}
	});
	alertify.alert(msg);
};


Publivate.yesno = function (msg, callback, cssClass) {
	alertify.set({
		labels: {
			ok: Joomla.JText._('JYES', 'Yes'), cancel: Joomla.JText._('JNO', 'No')
		}
	});
	alertify.confirm(msg, callback, cssClass);
};

Publivate.confirm = function (msg, callback, cssClass) {
	alertify.confirm(msg, callback, cssClass);
};

/**
 *  Translatel.
 * @param {
 string} from_lng From language either en,fr. Look at Google Translation Service.
 * @param {string} to_lng To language.
 * @param {string | jQuery} $source String or source of jquery object to translated.
 * @param {jQuery Object} $destination jQuery destination object.
 * @returns {undefined}
 */
Publivate.translate = function (from_lng, to_lng, $source, $destination, callback) {
	if (Publivate.gk == undefined || !Publivate.gk) {
		console.log('Google API key not set');
		return;
	}

	var source = ($source instanceof jQuery) ? $source.html() : $source;

	var data = {
		q: source,
		key: Publivate.gk,
		source: from_lng,
		target: to_lng
	};


	function translate(response) {
		var html = '';
		if (response.error) {
			Publivate.log(Joomla.JText._('UNABLE_TO_TRANSLATE', "Unable to translate."));
			return;
		}

		response.data.translations.forEach(function (item, i) {
			html += item.translatedText;
		});

		$destination.html(html);
		if (callback)
			callback();
	}

	jQuery.post('https://translation.googleapis.com/language/translate/v2', data, translate);

};

// Code off the net.
/**
 * Determent the center of the browsers window.
 */
function pv_center() {
	var hWnd = (arguments[0] != null) ? arguments[0] : {
		width: 0,
		height: 0
	};

	var _x = 0;
	var _y = 0;
	var offsetX = 0;
	var offsetY = 0;

//IE
	if (!window.pageYOffset)
	{
		//strict mode
		if (!(document.documentElement.scrollTop == 0))
		{
			offsetY = document.documentElement.scrollTop;
			offsetX = document.documentElement.scrollLeft;
		}
//quirks mode
		else
		{
			offsetY = document.body.scrollTop;
			offsetX = document.body.scrollLeft;
		}
	}
//w3c
	else
	{
		offsetX = window.pageXOffset;
		offsetY = window.pageYOffset;
	}
	var size = pv_size();
	_x = ((size.width - hWnd.width) / 2) + offsetX;
	_y = ((size.height - hWnd.height) / 2) + offsetY;

	return {
		x: _x,
		y: _y
	};
}
;

/**
 * Determine the size of the Browser window viewport
 */
function pv_size()
{
	var w = 0;
	var h = 0;

	//IE
	if (!window.innerWidth)
	{
		//strict mode
		if (!(document.documentElement.clientWidth == 0))
		{
			w = document.documentElement.clientWidth;
			h = document.documentElement.clientHeight;
		}
//quirks mode
		else
		{
			w = document.body.clientWidth;
			h = document.body.clientHeight;
		}
	}
//w3c
	else
	{
		w = window.innerWidth;
		h = window.innerHeight;
	}
	return {
		width: w,
		height: h
	};
}

/**
 * Remove html and entities and reutrn just the text
 * @param {
 bool} [images] Default to false. Leave images.
 * @return string Text - no html.
 */
function removeHtml(value) {
	// Remove all html to return content only.
	value = value.replace(/<\/?[^>]+(>|$)/g, "");
	value = value.replace(/[&nbsp;]|[\s]|[\n]/g, "");

	return value;
}

/**
 * Tests if a string has content other than html. Can include video, audion or image content.
 *
 * @param {
 string} value
 * @param {boolean} image Default to false to ignore image. True to include image.
 * @param {boolean} Default to false to ignore media. True to include media such video, audio, img or iframe for youtube.
 * @param {string} [placeholder] Defaults to class="placeholder" looks for place holder text.
 * @returns {Boolean}
 */
function hasContent(value, image /** ,media = false, placeholder **/) {
	var value = value + ""; //Ensure value is a string
	var image = arguments[1] || false;
	var media = arguments[2] || false;
	var placeholder = arguments[3] || 'class="placeholder"';

	var search = value.search(placeholder);

	if (search >= 0) {
		return false;
	}

//Allow image in the content if image is set to true.
	if (image) {
		var regex = /<img.*?>/gmi;
		if (regex.exec(value) !== null) {
			return true;
		}
	}


	if (media) {
		var regex = /<img.*?>/gmi;
		if (regex.exec(value) !== null) {
			return true;
		}

		if (/<iframe.*?>/gmi.exec(value) !== null) {
			return true;
		}

		if (/<video.*?>/gmi.exec(value) !== null) {
			return true;
		}
		if (/<audio.*?>/gmi.exec(value) !== null) {
			return true;
		}
	}

	// Remove all html to return content only.
	var subject = value.replace(/<\/?[^>]+(>|$)/g, "");
	// do inline white space only text.
	subject = subject.replace(/&nbsp;|\s|\n/g, "");

	return subject != '';
};

Publivate.hasContent = hasContent;

// LTrim(string) : Returns a copy of a string without leading spaces.
function ltrim(str)
{
	var whitespace = new String(" \t\n\r");
	var s = new String(str);
	if (whitespace.indexOf(s.charAt(0)) != -1) {
		var j = 0, i = s.length;
		while (j < i && whitespace.indexOf(s.charAt(j)) != - 1)
			j++;
		s = s.substring(j, i);
	}
	return s;
}

//RTrim(string) : Returns a copy of a string without trailing spaces.
function rtrim(str)
{
	var whitespace = new String(" \t\n\r");
	var s = new String(str);
	if (whitespace.indexOf(s.charAt(s.length - 1)) != -1) {
		var i = s.length - 1;       // Get length of string
		while (i >= 0 && whitespace.indexOf(s.charAt(i)) != - 1)
			i--;
		s = s.substring(0, i + 1);
	}
	return s;
}

// Trim(string) : Returns a copy of a string without leading or trailing spaces
function trim(str) {
	return rtrim(ltrim(str));
}


/**
 * @param id Id of the Wait Img
 * @param target Id(string) or jQuery element.
 * @param place - before, after, append
 * @param comment - Wait comment.
 */
function showWaitImg(id, target, place, comment) {
	place = (arguments[2]) ? arguments[2] : 'before';

	if (typeof target === 'string') {
		if (target[0] !== "#") {
			target = '#' + target;
		}
		target = jQuery(target);
	}

	var classes = (arguments[4]) ? ' ' + arguments[4] : '';

	var img = jQuery('<img>').attr({
		'class': 'wait',
		src: Publivate.baseUrl + 'images/clientadmin/wait16trans.gif',
		id: id
	});

	// Only display on wait image
	if (jQuery('#' + id).length) {
		return;
	}

	var commentSpan = jQuery('<span>').attr({
		id: 'wait-comment' + id,
		class: 'wait-comment',

	}).text(comment || '');

	switch (place) {
		case 'after':
			target.after(img).after(commentSpan);
			break;
		case 'append':
			target.append(img);
			break;
		case 'before':
		default:
			target.before(img);
	}

	return img;
}

/**
 * Hide wating spinner image
 * @param {
 type} id
 * @returns {undefined}
 */
function hideWaitImg(id, commentId) {
	commentId = (commentId) ? '#' + commentId : '#wait-comment' + id;
	if (typeof id === 'string') {
		if (id[0] !== "#") {
			id = '#' + id;
		}
	}

	jQuery(id).remove();
//	jQuery(commentId).remove();
}

function showResponse(target, text, place, duration) {
	duration = (arguments[2]) ? arguments[2] : 2000;
	var msg = new Element('div', {'class': 'ajax-msg'});
	msg.innerHTML = text;
	msg.inject($(document.body));
	msg.position({
		relativeTo: $(target)});
	msg.set('tween', {
		duration: duration,
		onComplete: function () {
			msg.dispose();
		}
	});
	msg.fade('out');
}

/**
 * Find if an specific option exists.
 * @param array options List of options
 * @param string id Id or value of the option to find.
 */
function findOption(options, id) {
	for (var i = 0; i < options.length; i++) {
		if (id == options[i].value) {
			return true;
		}
	}

	return false;
}

jQuery(function ($) {

	/**
	 * Add Joomla Extra validator tests.
	 *
	 */
	if (document.formvalidator) {
		// Empty text field test.
		document.formvalidator.setHandler('text', function (value) {
			return trim(value) != '';
		});

		document.formvalidator.setHandler('time', function (value) {
			var result = /^(0?[0-9]|1[012]):[0-5][0-9] ?(am|AM|pm|PM)$/.test(value);
			return result;
		});

		document.formvalidator.setHandler("email", function (value) {
			var result = /^[a-zA-Z0-9.!#$%&‚Äô*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]{2,4})+$/.test(value);
			return result;
		});

		document.formvalidator.setHandler("date", function (value) {
			var result = /^[0-9]{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])/.test(value);
			return result;
		});

		document.formvalidator.setHandler('greaterthanzero', function (value) {
			var regex = /^\d*$/;

			if (!regex.test(value)) {
				return false;
			}

			return parseInt(value) > 0;
		});
	}

	/** Detect Wet API **/
	if (typeof wb !== 'undefined' && wb && wb.doc) {
		var $document = wb.doc,
			componentName = "wb-lbx",
			selector = "." + componentName,
			dependenciesLoadedEvent = "deps-loaded" + selector
		Publivate.$document = $document;
		Publivate.dependenciesLoadedEvent = dependenciesLoadedEvent;
	}

});


/**
 * Validate an instance of tinyMCE editor content.
 * @param {
 type} editor - TinyMCE editor instance.
 * @param string text Text of the content
 * @returns {Boolean}
 */
function validateEditor(editor, text) {
	var succeed = hasContent(text);
	if (succeed) {
		jQuery("label[for='" + editor.id + "']").removeClass('invalid');
		jQuery(tinyMCE.activeEditor.getContainer()).removeClass('invalid');
	} else {
		jQuery("label[for='" + editor.id + "']").addClass('invalid');
		jQuery(tinyMCE.activeEditor.getContainer()).addClass('invalid');
	}
	return succeed;
}

if (!Array.prototype.find) {
	Array.prototype.find = function (predicate) {
		if (this == null) {
			throw new TypeError('Array.prototype.find called on null or undefined');
		}
		if (typeof predicate !== 'function') {
			throw new TypeError('predicate must be a function');
		}
		var list = Object(this);
		var length = list.length >>> 0;
		var thisArg = arguments[1];
		var value;

		for (var i = 0; i < length; i++) {
			value = list[i];
			if (predicate.call(thisArg, value, i, list)) {
				return value;
			}
		}
		return undefined;
	};
}

/**
 * Randonmize an array.
 * @param {
 function} callback
 * @param {string} position
 * @returns boolean
 */
Array.prototype.randomize = function (callback, position)
{
	var i, j, temp, item;

	if (typeof callback === 'function') {
		item = this.remove(callback);
	}

	i = this.length;

	while (--i)
	{
		j = Math.floor(Math.random() * (i - 1));
		temp = this[i];
		this[i] = this[j];
		this[j] = temp;
	}

	if (item && callback && position) {
		switch (position) {
			case 'first':
				this.unshift(item);
				break;
			case 'last':
				this.push(item);
				break;
		}
	}
};

if (!Array.prototype.remove) {
	Array.prototype.remove = function (fun) {
		'use strict';

		if (this === void 0 || this === null) {
			throw new TypeError();
		}

		if (typeof fun !== 'function') {
			throw new TypeError();
		}

		var t = Object(this);
		var len = t.length >>> 0;

		for (var i = 0; i < len; i++) {
			if (fun(t[i])) {
				var tmp = t.splice(i, 1);
				return tmp[0];
			}
		}
		return undefined;
	};
}


if (!Array.prototype.filter) {
	Array.prototype.filter = function (fun/*, thisArg*/) {
		'use strict';

		if (this === void 0 || this === null) {
			throw new TypeError();
		}

		var t = Object(this);
		var len = t.length >>> 0;
		if (typeof fun !== 'function') {
			throw new TypeError();
		}

		var res = [];
		var thisArg = arguments.length >= 2 ? arguments[1] : void 0;
		for (var i = 0; i < len; i++) {
			if (i in t) {
				var val = t[i];
				// NOTE: Technically this should Object.defineProperty at
				//       the next index, as push can be affected by
				//       properties on Object.prototype and Array.prototype.
				//       But that method's new, and collisions should be
				//       rare, so use the more-compatible alternative.
				if (fun.call(thisArg, val, i, t)) {
					res.push(val);
				}
			}
		}

		return res;
	};
}

if( !String.prototype.hasContent ){

	String.prototype.hasContent = function(image /** ,media = false, placeholder **/){
	    var str = this.valueOf();
		return Publivate.hasContent(str, image, arguments[2] || null);
	};
}

/**
 * Move or scroll to top of page for workbooks.
 * @returns {
 undefined}
 */
function moveTop() {
	jQuery('html,body').animate({scrollTop: 0}, 'slow');
	return false;
}

/**
 * Track form submission
 * @param {
 string} formName name of form which need to be tracked
 * @param {string} formEvent event action on the form
 * @param {string} trackingType tracking type
 * @return {undefined}
 */
function trackFormSubmission(formName, formEvent, trackingType) {
	if (trackingType == 'ga') {
		if (ga !== undefined) {
			ga('send', 'event', formName, formEvent);
		}
	}
	return true;
}

